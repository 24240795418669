<template>
  <div :class="`formulate-input-element formulate-input-element--${context.class}`" :data-type="context.type">
    <ClassificationCard
      v-for="(classification, i) in classifications"
      :key="classification.id"
      :annotation-type="i"
      :category="category"
      :class-colors="classColors"
      :classification="classification"
      :classifications="classifications"
      :schema-type="schemaType" />
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import { nanoid } from 'nanoid'

const ClassificationCard = () => import('./classification/classification-card')

export default {
  name: 'classification-input',
  components: {
    ClassificationCard
  },
  props: {
    context: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const classifications = ref(null)
    const category = computed(() => props.context.slotProps.label.category)
    const schemaType = computed(() => props.context.slotProps.label.schemaType)
    const annotationType = computed(() => props.context.slotProps.label.annotationType)
    const classColors = ref([])

    watch(
      computed(() => classifications.value),
      () => {
        props.context.model = { ...classifications.value }
      },
      { deep: true }
    )

    onMounted(() => {
      classifications.value = props.context.model
    })

    watch(annotationType, (v) => {
      if (!classifications.value) {
        classifications.value = {}
      }

      for (const key in classifications.value) {
        if (v && !v.includes(key)) {
          delete classifications.value[key]
          classifications.value = { ...classifications.value }
        }

        if (classifications.value[key]) {
          for (let i = 0; i < classifications.value[key].classification_schema.length; i++) {
            const c = classifications.value[key].classification_schema[i]
            if (classColors.value) {
              if (!classColors.value.includes(c.value)) {
                classColors.value.push(c.value)
              }
            }
          }
        }
      }

      if (v && v.length > 0) {
        for (let i = 0; i < v.length; i++) {
          const d = v[i]
          if (!Object.keys(classifications.value).includes(d)) {
            classifications.value = {
              ...classifications.value,
              [d]: {
                id: nanoid(10),
                classification_schema: []
              }
            }
          }
        }
      }
    })

    watch(category, () => {
      classifications.value = {}
    })

    return {
      classifications,
      category,
      annotationType,
      classColors,
      schemaType
    }
  }
}
</script>
