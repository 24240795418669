import { useLog } from '@/app/core/lib/util'

const eventQueue = []

export const useEventManager = () => {
  const { error } = useLog()

  const pushEvent = (eventTarget, eventType, callback, options = false) => {
    eventQueue.push({
      eventTarget,
      eventType,
      callback,
      options: options,
      id: eventQueue.length
    })
    return { id: eventQueue.length - 1 }
  }

  const defaultRemoveOptions = { event: true, queue: true }

  const removeEvent = (eventId, removeOptions = defaultRemoveOptions) => {
    try {
      const filteredEvent = eventQueue.filter(({ id }) => id === eventId)
      const [{ eventTarget, eventType, callback, options }] = filteredEvent
      if (removeOptions.event) {
        eventTarget.removeEventListener(eventType, callback, options)
      }
      if (removeOptions.queue) {
        eventQueue.forEach(({ id }, idx) => {
          if (id === eventId) {
            eventQueue.splice(idx, 1)
          }
        })
      }
    } catch (err) {
      error('do not detect event id in eventQueue. please check id.')
    }
  }

  const subscribeAll = () => {
    for (const { eventTarget, eventType, callback, options } of eventQueue) {
      eventTarget.addEventListener(eventType, callback, options)
    }
  }

  const unsubscribeAll = () => {
    for (const { eventTarget, eventType, callback, options } of eventQueue) {
      eventTarget.removeEventListener(eventType, callback, options)
    }
  }

  return {
    pushEvent,
    removeEvent,
    subscribeAll,
    unsubscribeAll
  }
}

export const shortcutManager = () => {
  const { subscribeAll, unsubscribeAll } = useEventManager()

  const disableShortcuts = () => {
    if (window.unsubscribeTinykeys) {
      window.unsubscribeTinykeys()
      window.unsubscribeTinykeys = null
    }
    unsubscribeAll()
  }

  const enableShortcuts = () => {
    if (!window.unsubscribeTinykeys) {
      window.unsubscribeTinykeys = window.shortcutHelper()
    }
    subscribeAll()
  }

  return { disableShortcuts, enableShortcuts }
}
